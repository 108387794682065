exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-chillers-tsx": () => import("./../../../src/pages/chillers.tsx" /* webpackChunkName: "component---src-pages-chillers-tsx" */),
  "component---src-pages-compressors-tsx": () => import("./../../../src/pages/compressors.tsx" /* webpackChunkName: "component---src-pages-compressors-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-generators-tsx": () => import("./../../../src/pages/generators.tsx" /* webpackChunkName: "component---src-pages-generators-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-machinery-tsx": () => import("./../../../src/pages/machinery.tsx" /* webpackChunkName: "component---src-pages-machinery-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

